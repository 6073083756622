
.button1 {background-color:none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    display: inline-block;
    padding: 10px 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;

    border: none;
    /* box-shadow: 0 9px rgb(77, 77, 40); */
    margin-right: 5px;
    
} /* Blue */

.button2 {background-color: #ffffff;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    padding: 10px 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    border: none;
    /* box-shadow: 0 9px #999; */
    margin-right: 5px;} /* Blue */
.button3 {background-color: #ffffff;
    display: inline-block;
    padding: 10px 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    border: none;    
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    /* box-shadow: 0 9px #999; */
    margin-right: 5px;} /* Red */ 
.button4 {background-color: #f2f2f2; color: black;
    display: inline-block;
    padding: 10px 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    border: none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    /* box-shadow: 0 9px #999; */
    margin-right: 5px;} /* Gray */ 
.button5 {background-color: #ffffff;
    display: inline-block;
    padding: 10px 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    border: none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    /* box-shadow: 0 9px #999; */
    margin-right: 5px;
    
} /* Black */

.button6 {background-color: #ffffff;
    display: inline-block;
    padding: 10px 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    border: none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    /* box-shadow: 0 9px #999; */
    margin-right: 5px;
    
} /* Black */

.button1:hover {
    background-color: hsl(74, 94%, 51%);
    transition: 0.7s;
    
}
.button2:hover {
    background-color: hsl(74, 94%, 51%);
    transition: 0.7s;
    
}.button3:hover {
    background-color: hsl(74, 94%, 51%);
    transition: 0.7s;
    
}.button4:hover {
    background-color: hsl(74, 94%, 51%);
    transition: 0.7s;
    
}.button5:hover {
    background-color: hsl(74, 94%, 51%);
    transition: 0.7s;
}
.button6:hover {
    background-color: hsl(74, 94%, 51%);
    transition: 0.7s;
}

/* 
    .tr{
        margin-bottom: 10px;
        margin-top: 10px;
        
    } */

    .img {
        /* height: 60px;
        width: 60px; */
    }
    .img.relative {
        position: relative;
        right: 300px;
      }
